<template>
    <a :href="hrefLink" class="button-floating-module white-button click-link">
    <div class="button-1-floating-module white-button-text" :style="{ 'color': buttonColor }">
      {{buttonText}}
    </div>
    </a>
</template>

<script>
export default {
  name: "XButton",
  props: ["buttonColor", "hrefLink", "buttonText"]
};
</script>

<style>
.button-floating-module {
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  display: flex!important;
  gap: 10px;
  height: 43px;
  justify-content: center;
  margin-right: 1px;
  padding: 15px 20px;
  position: relative;
  width: 157px;
}

.button-1-floating-module {
  /*color: var(--jade);*/
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: var(--font-size-s);
  font-weight:700;
  letter-spacing: 0;
  line-height: 104.9px;
  margin-bottom: -47px;
  margin-top: -45px;
  position: relative;
  align-content: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
</style>
