<template>
  <div class="flex flex-col items-center">
    <div class="relative">
      <CircleIcon />
      <span class="text-headline-8 absolute z-20 top-[6px] left-[14px]">
        {{ stepNumber }}
      </span>
    </div>
    <span class="text-xl mt-4 font-bold text-center">{{ title }}</span>
    <span class="mt-2 max-w-[343px] text-center">{{ description }}</span>
  </div>
</template>

<script>
import CircleIcon from './CircleIcon.vue';

export default {
  components: {
    CircleIcon
  },
  props: ['stepNumber', 'title', 'description']
}
</script>

<style scoped></style>