<template>
  <div class="d-flex legal-container">
    <p class="x2022-gainbridge-in-nav-end col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">&copy; {{ curYear() }} Gainbridge®
      Insurance Agency, LLC. All Rights Reserved.</p>

    <terms-privacy-cookies class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" />
  </div>
</template>

<script>
import TermsPrivacyCookies from "./TermsPrivacyCookies.vue";

export default {
  data() {
    return {
      curYear: () => new Date().getFullYear()
    }
  },
  name: "FooterBottomRow",
  components: {
    TermsPrivacyCookies,
  },
  props: [
  ],
};
</script>

<style>
.legal-container {
  display: flex;
  flex-direction: column;
}

.x2022-gainbridge-in-nav-end {
  color: var(--black);
  height: 20px;
  letter-spacing: 0;
  line-height: 20.2px;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-family-aktiv_grotesk-regular);
}


@media (min-width: 992px) {
  .legal-container {
    flex-direction: row;
  }
}

@media (max-width: 575.98px) {
  .x2022-gainbridge-in-nav-end {
    color: var(--black);
    margin-top: 10px !important;
    margin-bottom: 10px;
    line-height: 20.2px;
    font-size: 11px;
  }

}
</style>
