<template>
  <div class="button-6-header-nav-mobile">
    <a :href="enrollmentLink" class="click-link">
      <div class="button-7-header-nav-mobile">
        {{ button }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "XButton22Mobile",
  props: ["button"],
  data() {
    return {
      enrollmentLink: getEnrollmentUrl()
    };
  },
};
</script>

<style>
.button-6-header-nav-mobile {
  align-items: center;
  background-color: var(--blue);
  border-radius: 3px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  margin-left: 10px;
  padding: 15px 20px;
  position: relative;
  margin-right: 30px;
  margin-left: 30px;
}

.button-7-header-nav-mobile {
  color: var(--white);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 104.9px;
  margin-bottom: -46.5px;
  margin-top: -44.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
</style>
