// domain is not present for cookies from our site's domain itself
const COOKIE_CODES_TO_COOKIE_NAMES = {
  C0001: [],
  C0002: [
    { name: "fs_lua", path: "/", domain: "fullstory.com" },
    { regex: /mp_[A-Za-z0-9]+_mixpanel/, path: "/", domain: window.location.hostname } // the format of their cookies is mp_XXX_mixpanel, where XXX is a random string
  ],
  C0003: [
    { name: "fs_uid", path: "/", domain: "fullstory.com" },
  ],
  C0004: [
    { name: "ELQSTATUS", path: "/", domain: "eloqua.com" },
    { name: "ELOQUA", path: "/", domain: "eloqua.com" },
  ],
  C0005: [],
}

export function cookieConsent() {
  const acceptedCookies = JSON.parse(localStorage.getItem("acceptedCookies"));

  if (acceptedCookies) {
    console.log("found acceptedCookies object in localStorage", acceptedCookies);
  }

  window.addEventListener("OneTrustGroupsUpdated", event => {
    const acceptedCookiesFromEvent = event.detail;
    console.log("event fired", acceptedCookiesFromEvent);
    console.log("full event", event);

    localStorage.setItem("acceptedCookies", JSON.stringify(acceptedCookiesFromEvent));
  });
}
