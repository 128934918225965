<template>
  <div class="explore-2-nav-end">
    <div class="explore-3-nav-end aktivgrotesk-bold-black-14px">Partnerships</div>
    <div class="frame-2-nav-end"><div class="resources-2-nav-end "><a :href="partnershipLink" class="link">Partnerships</a></div></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oneupLink: "/oneup.html",
      mygaLink: "/steadypace.html",
      faqLink: "/faq.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      termsconditionsLink: "/termsconditions.html",
      privacypolicyLink: "/privacypolicy.html",
      disclosuresLink: "/disclosures.html"
    }
  },
  name: "Explore",
};
</script>

<style>
.explore-2-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-right: -1px;
  position: relative;
  padding-bottom: 15px;
}

.explore-3-nav-end {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 0px;
  position: relative;
  /*white-space: nowrap;*/
  /*width: fit-content;*/
}

.frame-2-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11px;
  position: relative;
  /*width: fit-content;*/
}

.resources-2-nav-end {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0px;
  opacity: 0.5;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
  /*width: fit-content;*/
}
</style>
