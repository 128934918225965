<template>
  <a :class="yellowButtonStyles" :href="href">
    <slot></slot>
  </a>
</template>


<script>
import { getButtonStyles } from "@gainbridge-platform/frontend-component-library/dist/components/buttons/Button";

export default {
  name: 'YellowButtonLink',
  props: ['href', 'className'],
  data() {
    return {
      yellowButtonStyles: getButtonStyles({ type: 'primary', className: 'no-underline w-fit text-black-l1 bg-secondary-yellow-l1 hover:text-white ' + this.className })
    };
  },
}
</script>
