<template>
  <div class="center-text-page-titles">
    <div class="frame-10">
      <h1 class="title-title-text">{{ careers }}</h1>
      <p class="title-body-text" v-html="joinTheGainbridgeTeam"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CenterTextPageTitles",
  props: ["careers", "joinTheGainbridgeTeam"],
};
</script>

<style>
.center-text-page-titles {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /*margin-top: 180px!important;*/
  position: relative;
  /*width: fit-content;*/
  z-index: 1;
}

.frame-10 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.title-title-text {
  color: #808080;
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 14px;
  font-weight:700;
  letter-spacing: 0;
  line-height: 14.7px;
  margin-top: 0px;
  position: relative;
  text-align: center;
  /*white-space: nowrap;*/
  width: fit-content;
}

.title-body-text {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 64px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: 0;
  position: relative;
  text-align: center;
}

@media (max-width: 576px) {
  .title-body-text{
    font-family: var(--font-family-aktiv_grotesk-regular);
    font-size: 44px;
    font-weight: 400;
    line-height: 45px;
  }
}
</style>
