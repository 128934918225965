<template>
    <div class="center-image">
        <div class="center-image-container">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "CenterImage",
};
</script>

<style>
.center-image {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--black-2);
    width: 90%;
}

.center-image-container {
    display: flex;
    width: auto;
    max-height: 680px;
    justify-content: center;
    align-items: center;
}
</style>