<template>
  <ul
    class="text-body-small md:text-body-standard max-w-[874px] text-black-l1 list-disc ml-[30px] md:ml-[40px] flex flex-col gap-[24px]">
    <slot></slot>
  </ul>

</template>

<script>
export default {
  name: "UnorderedList",
  props: [],
};
</script>