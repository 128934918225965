const parityflexAPY = 5.3;
const fastbreakAPY = 5.5;
const steadypaceAPY = 5.5;
const higherYield = "3.8x";

const formatValue = (value) => value.toFixed(2);

const getParityFlexRate = (showNew, unformatted) => {
  const value = parityflexAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

const getFastBreakRate = (showNew, unformatted) => {
  const value = fastbreakAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

const getSteadyPaceRate = (showNew, unformatted) => {
  const value = steadypaceAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

export { getFastBreakRate, getParityFlexRate, getSteadyPaceRate, higherYield };
