<template>
  <div class="no-margin-row">
    <!--    <img class="line-7-2  col-lg-12"-->
    <!--         alt="Line 7"/>-->

    <div class="indent">
      <button class="header col-12 clickable w-full bg-transparent border-0 text-start" @click="toggleText">
        <div class="title-margin">
          <slot name="title"></slot>
          <div class="right">
            <span v-if="showText" class="cursor-pointer">-</span>
            <span v-else class="cursor-pointer">+</span>
          </div>
        </div>
      </button>
      <p v-show="showText" class="the-one-up-sm-rila-re disclosure-hidden">
        <slot name="text"></slot>
      </p>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false
    }
  },
  methods: {
    toggleText() {
      this.showText = !this.showText;
    }
  },
  name: "MinimizeComponent",
  props: {
    title: String,
    text: String,
  },

};
</script>

<style>
.line-7-2 {
  height: 3px;
  /*left: 172px;*/
  object-fit: cover;
  position: relative;
  margin-top: 20px !important;
  width: 100%;
  /*top: 5408px;*/
  /*width: 1096px;*/
}

.what-is-one-up-sm {
  /*left: 187px;*/
  letter-spacing: 0;
  line-height: 27.2px;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-medium);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  /*margin-top: 20px !important;*/
  /*top: 5421px;*/
}

.the-one-up-sm-rila-re {
  /*left: 187px;*/
  letter-spacing: 0;
  margin-bottom: 16px;
  line-height: 27px;
  position: relative;
  color: var(--black-4);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-right: 20px;
  /*width: 92%*/
  /*top: 5461px;*/
  /*width: 1016px;*/
}

.title-margin {
  margin-top: 16px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px !important;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right>span {
  position: relative;
  left: 10px;
}


.no-margin-row {
  border-top: 1px solid #E6E6E6;

  display: flex;
  flex-wrap: wrap;
}

.no-margin-row:last-child {
  border-bottom: 1px solid #E6E6E6;
}

.indent {
  margin-left: 5px;
  width: 95%;
}

.indent p>a {
  font-weight: 700;
  font-family: var(--font-family-aktiv_grotesk-bold);
  text-decoration: none;
}

.indent li>a {
  font-weight: 700;
  font-family: var(--font-family-aktiv_grotesk-bold);
}

.clickable {
  cursor: pointer;
}
</style>
