<template>
  <div class="frame-nav-end">
    <div class="resources-1-nav-end"><a :href="articlesLink" class="link">{{ children }}</a></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oneupLink: "/oneup.html",
      mygaLink: "/myga.html",
      articlesLink: "/articles.html",
      faqLink: "/faq.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      termsconditionsLink: "/termsconditions.html",
      privacypolicyLink: "/privacypolicy.html",
      disclosuresLink: "/disclosures.html"
    }
  },
  name: "Frame2",
  props: ["children"],
};
</script>

<style>
.frame-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 11px;
  position: relative;
  width: fit-content;
}

.resources-1-nav-end,
.about-us-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0px;
  opacity: 0.5;
  position: relative;
  width: fit-content;

  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}
</style>
