<template>
  <div class="w-full max-w-[874px] flex flex-col md:flex-row py-10 border-y-2 border-black-l6 gap-6 md:gap-[40px]">
    <img class="rounded-full h-[88px] w-[88px]" :src="authorImg" alt="default profile picture">
    <div class="flex flex-col gap-2">
      <div class="flex items-center gap-4">
        <div class="text-subhead-standard text-black-l2">
          {{ authorDetails.name }}, {{ authorDetails.credentials }}
        </div>
        <a v-if="authorDetails.link" :href="authorDetails.link" target="_blank">
          <LinkIcon className="h-6 w-6" />
        </a>
        <a v-if="authorDetails.twitter" :href="authorDetails.twitter" target="_blank">
          <TwitterIcon className="h-6 w-6" />
        </a>
        <a v-if="authorDetails.linkedIn" :href="authorDetails.linkedIn" target="_blank">
          <LinkedInIcon className="h-6 w-6" />
        </a>
        <a v-if="authorDetails.facebook" :href="authorDetails.facebook" target="_blank">
          <FacebookIcon className="h-6 w-6" />
        </a>
      </div>
      <div class="text-black-l2 text-body-small">

        {{ authorDetails.bio }}
      </div>
    </div>
  </div>
</template>

<script>
import { FacebookIcon } from "@gainbridge-platform/frontend-component-library/dist/components/icons/system/FacebookIcon";
import { LinkedInIcon } from "@gainbridge-platform/frontend-component-library/dist/components/icons/system/LinkedInIcon";
import { LinkIcon } from "@gainbridge-platform/frontend-component-library/dist/components/icons/system/LinkIcon";
import { TwitterIcon } from "@gainbridge-platform/frontend-component-library/dist/components/icons/system/TwitterIcon";
import { getAuthorDetails } from "../authors"
export default {
  name: "AuthorDetails",
  components: {
    FacebookIcon,
    LinkedInIcon,
    LinkIcon,
    TwitterIcon
  },
  props: ["authorId"],
  data: (vm) => {
    const authorDetails = getAuthorDetails(vm.authorId);

    return {
      authorDetails,
      authorImg: authorDetails.authorImg ? require('@/assets/images/authors/' + authorDetails.authorImg) : require('@/assets/images/authors/profile.png')
    }
  }
};
</script>