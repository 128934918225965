export const authors = [
  {
    id: "shannonreynolds",
    name: "Shannon Reynolds",
    credentials: "Licensed Insurance Agent",
    linkedIn: "https://www.linkedin.com/in/shannon-reynolds-1ba3704a/",
    bio: "Shannon is a director of customer support and operations at Gainbridge.",
  },

  {
    id: "giuliaolsson",
    name: "Giulia Olsson",
    linkedIn: "https://www.linkedin.com/in/giuliaolsson/",
    bio: "Giulia is a senior manager of business development at Gainbridge.",
  },

  {
    id: "jayantwalia",
    name: "Jayant Walia",
    credentials: "Head of Business Development",
    linkedIn: "https://www.linkedin.com/in/jayant-walia-a9084415/",
    bio: "Jayant is a director of business development at Gainbridge.",
  },

  {
    id: "oliviadelbarco",
    name: "Olivia del Barco",
    linkedIn: "https://www.linkedin.com/in/oliviadelbarco/",
    bio: "Olivia is a senior operations analyst at Gainbridge.",
  },

  {
    id: "zackguiliano",
    name: "Zack Guiliano",
    linkedIn: "https://www.linkedin.com/in/zack-guiliano/",
    bio: "Zack is an associate director of marketing at Gainbridge.",
  },

  {
    id: "joemolee",
    name: "Joe Lee",
    linkedIn: "https://www.linkedin.com/in/joemolee/",
    bio: "Joe is a vice president of operations and customer experience at Gainbridge.",
  },

  {
    id: "brandonlawler",
    name: "Brandon Lawler",
    credentials: "RICP®, AAMS™",
    linkedIn:
      "https://www.linkedin.com/in/brandon-lawler-ricp%C2%AE-aams%E2%84%A2-489537144/",
    bio: "Brandon is a financial operations and annuity specialist at Gainbridge.",
  },
  {
    id: "tiffanieharding",
    name: "Tiffanie Harding",
    credentials: "SIE and Series 6 License",
    linkedIn: "https://www.linkedin.com/in/tiffanie-harding/",
    bio: "Tiffanie is a licensed insurance agent and a digital support associate at Gainbridge.",
  },
  {
    id: "amandagile",
    name: "Amanda Gile",
    credentials: "Series 6 and 63 insurance license",
    linkedIn: "https://www.linkedin.com/in/amanda-gile-mba-0a109a24/",
    bio: "Amanda is a licensed insurance agent and digital support associate at Gainbridge.",
  },
  {
    id: "karenkxu",
    name: "Karen Xu",
    linkedIn: "https://www.linkedin.com/in/karenkxu/",
    bio: "Karen is a product manager at Gainbridge",
  },
  {
    id: "danchao",
    name: "Daniel Chao",
    linkedIn: "https://www.linkedin.com/in/danchao/",
    bio: "Daniel is a director of product management at Gainbridge.",
  },
  {
    id: "bridgetgains",
    name: "Bridget Gains",
    bio: "This is a test account",
  },
];

export const getAuthorDetails = (id) =>
  authors.find((author) => author?.id === id);
