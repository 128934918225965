<template>
  <div class="row row-risk-return-mygA row-risk-return-mobile">
    <ContentText
        :Title="titleText" :Content="contentText"
        class="col-lg-6  col-sm-12 order-risk-return"
    />

    <div class="steadypace-group col-lg-6  col-sm-12 ">
      <div class="content-image-container-steadypace">
        <img
            class="risks_return_image_steadypace"
            :src="srcImage"
            alt="group-pic"
        >
      </div>
    </div>
  </div>
</template>
<script>
import ContentText from "@/components/product/ContentText.vue"

export default {
  name: 'RightImageContentStandard',
  components: {ContentText},
  props: ["titleText", "contentText", "srcImage"]
}
</script>
<style>

.row-risk-return-mygA {
  margin-top: 150px !important;
}

.steadypace-group {
  order: 2;
  align-items: flex-start;
  display: flex;
  position: relative;
  justify-content: flex-end;
}


@media (min-width: 992px) {
}


@media (min-width: 576px) and (max-width: 992px) {
}


.risks_return_image_steadypace {
  object-fit: cover;
  width: 100%;
  height: auto;
  float: right;
}


@media (max-width: 992px) {
  .risks_return_image {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

}


.content-image-container-steadypace {
  height: 332px;
  display: block;
  position: relative;
  flex-display: row;
  width: 499px;
  overflow: hidden;
  border-radius: 3px;
}


@media (max-width: 991.98px) {
  .content-image-container-steadypace {
    height: auto;
    width: 100%;
  }

  .row-risk-return-mobile {
    margin-top: 100px !important;
    justify-content: center;
  }

  .steadypace-group {
    order: 1;
    align-items: center;
    display: flex;
    position: relative;
    margin-top: 10px !important;
    margin-left: 0px !important;
  }

}

@media (max-width: 575.98px) {

}


</style>