<template>
  <div class="center-text">
    <div class="container">
      <div class="superHeader" v-if="superHeader">{{ superHeader }}</div>
      <h1 class="header">{{ header }}</h1>
      <div class="copy">{{ copy }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CenterText",
  props: ["superHeader", "header", "copy"],
};
</script>

<style scoped>
.center-text {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.superHeader {
  color: var(--gray);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14.7px;
  margin-top: 0px;
  position: relative;
  text-align: center;
}

.header {
  color: var(--black-brand-l-1);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 64px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 70px;
  position: relative;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.copy {
  margin-bottom: 0;
  line-height: 36px;
  color: var(--black-2);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  max-width: 60%;
  text-align: center;
}

@media (max-width: 800px) {
  .copy {
    max-width: 100%;
  }
}
</style>
