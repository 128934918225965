<template>
  <div class="company-nav-end">
    <div class="company-1-nav-end aktivgrotesk-bold-black-14px">Company</div>
    <div class="about-us-2-nav-end ">
      <a :href="aboutusLink" class="link">
        About
      </a>
    </div>
    <div class="about-us-2-nav-end ">
      <a :href="careersLink" class="link">
        Careers
      </a>
    </div>

    <div class="about-us-2-nav-end ">
      <a :href="contactusLink" class="link">
        Contact
      </a>
    </div>

    <div class="about-us-2-nav-end ">
      <a :href="b2bLink" class="link" target="_blank">
        Enterprise
        <img :src="require('@/assets/images/header/new-tab.png')" alt="Open in new tab" />
      </a>
    </div>
  </div>
</template>

<script>
import Frame1 from "./Frame1.vue";
import Frame2 from "./Frame2.vue";

export default {
  data() {
    return {
      oneupLink: "/oneup.html",
      mygaLink: "/myga.html",
      faqLink: "/faq.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      termsconditionsLink: "/termsconditions.html",
      privacypolicyLink: "/privacypolicy.html",
      disclosuresLink: "/disclosures.html",
      b2bLink: "https://www.enterprise.gainbridge.io",
    }
  },
  name: "Company",
  components: {
    Frame1,
    Frame2,
  },
  props: ["frame1Props", "frame2Props"],
};
</script>

<style>
.company-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding-bottom: 15px;
}

.company-1-nav-end {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 0px;
  position: relative;
  /*white-space: nowrap;*/
  /*width: fit-content;*/
}

.about-us-2-nav-end {
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: relative;
  /*width: fit-content;*/
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.link {
  display: flex;
  gap: 4px;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}
</style>
