<script>
import MinimizeComponent from "@/components/MinimizeComponent.vue";

export default {
  name: "FAQ",
  props: [
    "header",
    "questions",
    "id"
  ],
  components: {
    MinimizeComponent
  }
};
</script>

<template>
  <div>
    <div class="faq-section-title-style aktivgrotesk-bold-black-24px text-headline-6">{{ header }}</div>

    <minimize-component v-for="(item, index) in questions" :key="index">
      <template v-slot:title>
        <h3 class="hidden-font-style-title-faq" :id="item.id">{{ item.question }}</h3>
      </template>
      <template v-slot:text>
        <p class="hidden-font-style-content-faq" v-html="item.answer"></p>
      </template>
    </minimize-component>
  </div>
</template>

<style scoped>
.faq-section-title-style {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30.72px;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  margin-bottom: 32px;
  padding-left: 2px !important;
}

.hidden-font-style-title-faq {
  line-height: 27.2px;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-medium);
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  fill: var(--black);
}

.hidden-font-style-content-faq {
  line-height: 24px;
  position: relative;
  color: #747474;
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 16px;
}

/* .hidden-font-style-title-faq::before {
  content: "Q: ";
}

.hidden-font-style-content-faq::before {
  content: "A: ";
} */
</style>