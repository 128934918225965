<template>
  <div :class="[`frame-1-nav-end`, className || ``]">
    <div class="autocapture-nav-end aktivgrotesk-medium-black-14px">{{ children }}</div>
  </div>
</template>

<script>
export default {
  name: "Frame1",
  props: ["children", "className"],
};
</script>

<style>
.frame-1-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: fit-content;
}

.autocapture-nav-end {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0px;
  opacity: 0.5;
  position: relative;
  width: fit-content;
}

.frame-1-nav-end.frame-4 {
  gap: 11px;
}
</style>
