<template>
  <div class="articles-image-container">

    <img class="articles-standard-image" :src="src" alt="articles image" />
  </div>

</template>

<script>
export default {
  name: "ArticlesStandardImage",
  props: ["src"],
};
</script>

<style>
.articles-image-container {
  position: relative;
  height: 592px;
  width: auto;
}

.articles-standard-image {
  max-width: 100%;
  max-height: 100%;
}


@media (max-width: 575.98px) {
  .articles-standard-image {
    font-size: 16px;
    line-height: 24px;
  }

  .articles-image-container {
    height: 390px;

  }
}


@media (max-width: 375px) {
  .articles-image-container {
    height: 254px;

  }
}
</style>
