<template>
  <div class="flex flex-col items-center gap-[15px] max-w-[874px] w-full">
    <div class="text-black-l4 text-eyebrow uppercase">
      Articles
    </div>
    <h1 class="text-headline-4 md:text-headline-2 text-center text-black-l1">
      <slot></slot>
    </h1>
    <div class="flex md:gap-4 text-center items-center justify-center flex-col md:flex-row">
      <div v-if="authorDetails.name" class="text-subhead-standard text-black-l1">By {{ authorDetails.name }}, {{
        authorDetails.credentials }}</div>
      <div v-if="date" class="text-body-standard text-black-l1">{{ date }}</div>
    </div>
  </div>

</template>

<script>
import { getAuthorDetails } from "../authors";

export default {
  name: "Header",
  props: ["date", "authorId"],
  data: (vm) => {
    const authorDetails = vm.authorId ? getAuthorDetails(vm.authorId) : {};

    return {
      authorDetails,
    }
  }
};
</script>