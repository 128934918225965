<template>
  <div class="frame-76-1">
    <div class="introduction-1"><slot name="title"></slot></div>
    <p class="gainbridge-insurance">
      <slot name="text"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "TitleContent",
  props: {
    title: String,
    text: String,
  },
};
</script>

<style>
.frame-76-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  /*width: fit-content;*/
}

.introduction-1,
.california-privacy-policy,
.california-collection-notice {
  position: relative;
  /*width: fit-content;*/
}

.gainbridge-insurance,
.last-revised-on-63,
.the-personal-informa {
  position: relative;
}
</style>
