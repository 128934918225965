<template>
  <a :href="loginUrl" class="click-link button-4-header-nav log-in-button-white">
    <div class="button-5-header-nav log-in-button-white-text">
      {{ button }}
    </div>
  </a>
</template>

<script>
import { getEnrollmentUrl } from '../utils';

export default {
  name: "XButton3",
  props: ["button"],
  data() {
    return {
      loginUrl: getEnrollmentUrl('login'),
    };
  },
};
</script>

<style>
.button-4-header-nav {
  align-items: center;
  border: 2px solid;
  border-color: var(--blue);
  border-radius: 3px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;

  margin-left: 30px;
  padding: 18px 20px;
  position: relative;
  width: fit-content;
}

.button-5-header-nav {
  color: var(--blue);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: -46.5px;
  margin-top: -44.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

@media (max-width: 905px) {
  .button-4-header-nav {
    align-items: center;
    border: 2px solid;
    border-color: var(--blue);
    border-radius: 3px;
    display: flex;
    gap: 10px;
    height: 40px;
    justify-content: center;
    margin-right: 30px;
    padding: 15px 20px;
    position: relative;
    width: 93%;
    margin-bottom: 18px;
  }
}


@media (max-width: 1199.98px) {
  .button-4-header-nav {}
}

.click-link {
  text-decoration: none;
}
</style>
