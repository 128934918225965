<template>
  <div class="footer-1-nav-end">
    <div class="overlap-group1-3-nav-end">
      <div class="row">
        <product class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4"/>
        <div class="explore-nav-end col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
          <div class="explore-1-nav-end aktivgrotesk-bold-black-14px">Resources</div>
          <frame2 children="Articles"/>
          <div class="resources-nav-end"><a :href="faqLink" class="link">FAQ</a></div>
        </div>

        <company class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4"/>
        <explore class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4"/>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
          <div class="socials-container">
            <a href="https://www.linkedin.com/company/gainbridge.life" class="socials-nav-end">
              <img class="footer-socials-img" :src="require('@/assets/images/footer/Linkedin.png')" alt="Linkedin"/>
            </a>

            <a href="https://www.facebook.com/GainbridgeLife/" class="socials-nav-end">
              <img class="footer-socials-img" :src="require('@/assets/images/footer/Facebook.png')" alt="Facebook"/>
            </a>

            <a href="https://twitter.com/GainbridgeLife" class="socials-nav-end">
              <img class="footer-socials-img" :src="require('@/assets/images/footer/Twitter.png')" alt="Twitter"/>
            </a>
          </div>


          <!--          <img class="socials-nav-end" :src="require('@/assets/images/footer/socials-1@2x.png')" alt="Socials"/>-->
        </div>
      </div>
      <div class="divider-nav-end"></div>


      <footer-bottom-row />

    </div>
  </div>
</template>

<script>
import Company from "./Company.vue";
import Explore from "./Explore.vue";
import FooterBottomRow from "./FooterBottomRow.vue";
import Frame2 from "./Frame2.vue";
import Product from "./Product.vue";
import TermsPrivacyCookies from "./TermsPrivacyCookies.vue";

export default {
  data() {
    return {
      oneupLink: "/oneup.html",
      mygaLink: "/steadypace.html",
      articlesLink: "/articles.html",
      faqLink: "/faq.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      termsconditionsLink: "/termsconditions.html",
      privacypolicyLink: "/privacypolicy.html",
      disclosuresLink: "/disclosures.html"
    }
  },
  name: "Footer3",
  components: {
    Product,
    Frame2,
    Company,
    Explore,
    TermsPrivacyCookies,
    FooterBottomRow
  },
  props: [
    "explore", 
    "resources", 
    "socials", 
    "x2022GainbridgeIn", 
    "productProps", 
    "frame2Props", 
    "companyProps"
  ],
};
</script>

<style>
.footer-1-nav-end {
  align-items: flex-start;
  background-color: var(--white);
  /*border: 1px solid;*/
  /*border-color: var(--mercury);*/
  display: flex;
  flex: 1;
  /*height: 100vh;*/
  /*width: 1440px;*/
}

.overlap-group1-3-nav-end {
  background-color: #f8f8f9;
  display: flex;
  height: fit-content;
  flex-direction: column;
  /*min-height: 844px;*/
  padding: 5% 7%;
  width: 100%;
}

.explore-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding-bottom: 15px;
  /*width: fit-content;*/
}

.explore-1-nav-end {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 0px;
  position: relative;
  /*white-space: nowrap;*/
  /*width: fit-content;*/
}

.resources-nav-end {
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: relative;

  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
  /*width: fit-content;*/
}

.socials-nav-end {
  position: relative;
  right: 0;
  /*margin-left: 7%;*/
  /*height: 36px;*/
  /*width: 36px;*/
  /*margin: 8px;*/
  padding: 8px;
}

.socials-nav-end-end{
  height: 36px;
  width: 36px;
  position: relative;
  right: 0;
}

.socials-container {
  position: absolute;
  right: -10px;
  padding-right: 7%;
  width: 260px;
  display: flex;
  justify-content: flex-end;
}

.divider-nav-end {
  background-color: var(--black);
  border: none;
  height: 1px;
  margin-top: 70px;
  opacity: 0.06;
  width: 100%;
  margin-bottom: 20px;
}

.overlap-group-6-nav-end {
  align-self: flex-start;
  height: fit-content;
  margin-top: 28px;
  position: relative;
  width: 100%;
}

.group-59-nav-end {
  display: block;
  flex-direction: column;
  gap: 30px;
  /*height: 530px;*/
  left: 0;
  position: relative;
  top: 0;
  /*width: 1100px;*/
}

.address-nav-end {
  /*height: 480px;*/
  letter-spacing: 0;
  line-height: 20.2px;
  text-align: justify;
  /*width: 1096px;*/
}


@media (max-width: 768px) {

}

@media (max-width: 575.98px) {
  .socials-container {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 0;

  }
}

.footer-socials-img{
  width: 35px;
  height: 35px;
}
</style>
