import * as LDClient from 'launchdarkly-js-client-sdk';

/**
 * make sure that, when using this function, all of your logic that
 * happens after component mount happens in the *callback that you pass
 * to this function.* component mount does not fully happen until
 * AFTER the launchDarkly call is resolved.
 */
export function initializeLaunchDarkly(targetKey, onFfRetrieval) {
  const clientKey = process.env.LAUNCH_DARKLY_KEY;

  // Initialize the LaunchDarkly client
  const ldClient = LDClient.initialize(clientKey, { key: 'all-users' });

  ldClient.on('ready', () => {
    // Once the client is ready, check the feature flag
    const featureFlag = ldClient.variation(targetKey, false);

    onFfRetrieval(featureFlag);
    
    // You can also listen for flag changes in real-time
    ldClient.on(`change:${targetKey}`, (newValue) => {
      onFfRetrieval(newValue);
    });
  });
}