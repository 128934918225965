<template>
  <div class="articles-quote-container">
    <p class="articles-quote-text">
      {{ insertText }}<slot></slot>
    </p>
    <p class="articles-quote-identity">
      {{ quoteIdentity }}
    </p>
  </div>

</template>

<script>
export default {
  name: "ArticlesQuoteText",
  props: ["insertText", "quoteIdentity"],
};
</script>

<style>
.articles-quote-container {
  flex-direction: column;
  max-width: 874px;
  display: flex;
  gap: 28px;
  border-left: 2px solid black;
  padding-left: 30px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.articles-quote-text {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 34px;
}

.articles-quote-identity {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-thin);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 34px;
}

@media (max-width: 575.98px) {
  .articles-quote-text {
    font-size: 18px;
    line-height: 30.6px;
  }

  .articles-quote-identity {
    font-size: 18px;
    line-height: 30.6px;
  }
}
</style>
