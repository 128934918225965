<template>
  <div class="w-full flex flex-col gap-[15px] max-w-[874px]">
    <h3 class="text-headline-7 text-black-l1">{{ title }}</h3>
    <slot></slot>
  </div>


</template>

<script>
export default {
  name: "H3Container",
  props: ["title"],
};
</script>