<template>
  <svg class="shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 60">
      <g id="Group 47">
        <circle id="Ellipse 8" opacity="0.5" cx="10" cy="10" r="10" fill="#1040B3" />
        <circle id="Ellipse 11" cx="9.9999" cy="9.9999" r="7.6" fill="#1040B3" />
      </g>
      <path id="Vector"
        d="M8.21355 14.4001L4.80002 10.8835L6.35562 9.28092L8.21355 11.2006L13.6444 5.6001L15.2 7.20267L8.21355 14.4001Z"
        fill="white" />
    </g>
  </svg>

</template>

<script>
export default {
  name: "SystemIconsSuccess",
};
</script>
