<template>
  <div class="start-button-container-1">
    <a :href="link">
      <div class="start-button-container-yellow-button yellow-button">
        <div class="start-button-yellow yellow-button-text">{{text}}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "StartButton",
  props: [
      "link",
      "text",
  ]
};
</script>

<style>
.start-button-container-1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.start-button-container-1 a {
  display: inline-block;
  width: 100%;
}
.start-button-container-yellow-button {
  align-items: center;
  border-radius: 3px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
}

.start-button-yellow {
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: -46.5px;
  margin-top: -46.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
  font-family: var(--font-family-aktiv_grotesk-bold);
}

@media (max-width: 991.98px) {
  .button-container {
    justify-content: center;
  }
}
</style>
