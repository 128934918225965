<template>
  <a :href="enrollmentLink" class="click-link button-6-header-nav blue-button">
    <div class="button-7-header-nav blue-button-text">
      {{ button }}
    </div>
  </a>
</template>

<script>
export default {
  name: "XButton22",
  props: ["button", "enrollmentLink"],
};
</script>

<style>
.button-6-header-nav {
  align-items: center;
  background-color: var(--blue);
  border-radius: 3px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  margin-left: 10px;
  padding: 20px;
  position: relative;
  width: fit-content;
}

.button-7-header-nav {
  color: var(--white);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: -46.5px;
  margin-top: -44.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

@media (max-width: 905px) {
  .button-6-header-nav {
    align-items: center;
    background-color: var(--blue);
    border-radius: 3px;
    display: flex;
    gap: 10px;
    height: 40px;
    justify-content: center;
    margin-left: 10px;
    padding: 15px 20px;
    position: relative;
    margin-right: 30px;
    margin-left: 30px;
    width: 93%;
  }
}
</style>
