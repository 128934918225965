export function getEnrollmentUrl(product) {
  const partnerId = sessionStorage.getItem("partnerId");

  // In some cases, getEnrollmentUrl is called before the page is mounted,
  //    causing the url to be calculated before the params are saved
  saveUTMParams();
  const utmParams = getUTMParams();

  let targetUrl = `${process.env.ENROLLMENT_URL}/state-selection`;
  let queryParams = {};
  switch (product) {
    case "steadypace":
      queryParams = {
        product: "steadypace",
      };
      break;
    case "fastbreak":
      queryParams = {
        product: "fastbreak",
      };
      break;
    case "parityflex":
      queryParams = {
        product: "parityflex",
      };
      break;
    case "oneup":
      queryParams = {
        product: "oneup",
      };
      break;
    case "login":
      targetUrl = `${process.env.ENROLLMENT_URL}/login`;
      break;
  }

  const targetQueryParams = {
    ...(partnerId ? { partnerId } : {}),
    ...(utmParams ? utmParams : {}),
    ...(queryParams || {}),
  };

  if (Object.keys(targetQueryParams).length > 0) {
    targetUrl += `?${new URLSearchParams(targetQueryParams).toString()}`;
  }

  return targetUrl;
}

export function saveUTMParams() {
  let queryString = window.location.search;
  let queryParams = new URLSearchParams(queryString);

  const targetParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];

  const utmParams = targetParams
    .filter((key) => !!queryParams.get(key))
    .reduce((prev, key) => ({ ...prev, [key]: queryParams.get(key) }), {});

  // Only overwrite if a new utm params set come in
  if (!!Object.keys(utmParams).length) {
    sessionStorage.setItem("utmParams", JSON.stringify(utmParams));
  }
}

function getUTMParams() {
  try {
    return JSON.parse(sessionStorage.getItem("utmParams"));
  } catch (e) {
    return {};
  }
}
