<template>
  <ol
    class="text-body-small md:text-body-standard max-w-[874px] text-black-l1 list-decimal ml-[30px] md:ml-[40px] flex flex-col gap-[24px]">
    <slot></slot>
  </ol>

</template>

<script>
export default {
  name: "OrderedList",
  props: [],
};
</script>