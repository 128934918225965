<template>
  <div class="top-margin-spacer">

  </div>
</template>

<script>
export default {
  name: "HeadingSpacer"
};
</script>

<style>
.top-margin-spacer {
  margin-top: 160px;
}
</style>
