<template>
  <div class="terms-privacy-cookies-nav-end">
    <div class="terms-privacy-cookies-item-nav-end"><a :href="termsconditionsLink" class="link">Terms</a></div>
    <div class="terms-privacy-cookies-item-nav-end"><a :href="privacypolicyLink" class="link">Privacy Policies</a></div>
    <div class="terms-privacy-cookies-item-nav-end"><a :href="disclosuresLink" class="link">Legal Disclosures &amp;
        Documents</a></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oneupLink: "/oneup.html",
      mygaLink: "/myga.html",
      faqLink: "/faq.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      termsconditionsLink: "/termsconditions.html",
      privacypolicyLink: "/privacypolicy.html",
      disclosuresLink: "/disclosures.html"
    }
  },
  name: "TermsPrivacyCookies",
};
</script>

<style>
.terms-privacy-cookies-nav-end {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
  padding-top: 2px;

  justify-content: flex-end;
  display: flex;
  gap: 16px 5%;
  /*left: 799px;*/
  position: relative;
  /*width: fit-content;*/
}

.terms-privacy-cookies-item-nav-end {
  letter-spacing: 0;
  line-height: 13px;
  /*position: relative;*/
  /*display: inline-block;*/
  /*white-space: nowrap;*/
  /*width: fit-content;*/
}

@media (max-width: 575.98px) {
  .terms-privacy-cookies-nav-end {
    justify-content: center;
    display: flex;
    gap: 5%;
    position: relative;
  }
}

@media (max-width: 992px) {
  .terms-privacy-cookies-nav-end {
    justify-content: flex-start;
    display: flex;
    gap: 5%;
    position: relative;
    flex-wrap: wrap;
    gap: 16px 5%;
  }
}

@media (max-width: 576px) {
  .terms-privacy-cookies-nav-end {
    font-size: 12px;
    margin-top: 10px !important;
  }
}
</style>
