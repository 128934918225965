<template>
  <div class="navigation-1-header-nav" ref="navigation">
    <div class="header-constrain">
      <div class="frame-9-header-nav">
        <a :href="homeLink" class="logo-link">
          <img class="logo-header-nav" :src="logo" alt="Logo" style="max-width: unset;" />
        </a>
        <div class="navbar aktivgrotesk-medium-black-14px">
          <div class="navbar-link-header-nav" :class="{
          'nav-bar-link-products-active': isProductsVariableTrue,
          'nav-bar-link-products-oneup-active': isProductOneUpVariableTrue,
          'nav-bar-link-products-steadypace-active': isProductSteadyPaceVariableTrue,
          'nav-bar-link-products-fastbreak-active': isProductFastBreakVariableTrue,
          'nav-bar-link-products-parityflex-active': isProductFastBreakVariableTrue,
          'nav-bar-link-products-coming-soon-active': isProductComingSoonTrue
        }" @mouseenter="showDropdown('products')" @mouseover="showDropdown('products')"
            @mouseleave="hideDropdown('products')">{{ navbarLinkProducts }}
            <div v-if="showProductsDropdown" class="dropdown-menu aktivgrotesk-medium-black-14px"
              @mouseenter="showDropdown('products')" @mouseleave="hideDropdown('products')">
              <a :href="steadypaceLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">SteadyPace</div>
              </a>
              <a :href="fastbreakLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">FastBreak</div>
              </a>
              <a :href="parityflexLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">ParityFlex</div>
              </a>
              <a :href="oneupLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">OneUp &dash; Coming soon</div>
              </a>
              <a :href="protectLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">Gainbridge Protect</div>
              </a>
            </div>
          </div>
          <div class="navbar-link-header-nav" :class="{ 'nav-bar-link-resources-active': isResourceVariableTrue }"
            @mouseenter="showDropdown('resources')" @mouseover="showDropdown('resources')"
            @mouseleave="hideDropdown('resources')">{{ navbarLinkResources }}
            <div v-if="showResourcesDropdown" class="dropdown-menu aktivgrotesk-medium-black-14px"
              @mouseleave="hideDropdown('resources')">
              <a :href="articlesLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">Articles</div>
              </a>
              <a :href="faqLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">FAQ</div>
              </a>
            </div>
          </div>
          <div class="navbar-link-header-nav" :class="{ 'nav-bar-link-company-active': isCompanyVariableTrue }"
            @mouseenter="showDropdown('company')" @mouseover="showDropdown('company')"
            @mouseleave="hideDropdown('company')">{{ navbarLinkCompany }}
            <div v-if="showCompanyDropdown" class="dropdown-menu aktivgrotesk-medium-black-14px"
              @mouseleave="hideDropdown('company')">
              <a :href="aboutusLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">About Us</div>
              </a>
              <a :href="careersLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">Careers</div>
              </a>
              <a :href="contactusLink" class="link">
                <div class="dropdown-menu-item aktivgrotesk-medium-black-14px">Contact</div>
              </a>
              <div class="gray-border-top-line-header"></div>

              <a :href="b2bLink" class="link" target="_blank">
                <div class="flex gap-1 items-center dropdown-menu-item aktivgrotesk-medium-black-14px">Enterprise
                  <img :src="require('@/assets/images/header/new-tab.png')" alt="Open in new tab"
                    class="new_tab_icon" />
                </div>
              </a>
            </div>
          </div>
          <div class="navbar-link-header-nav-end aktivgrotesk-medium-black-14px"
            :class="{ 'nav-bar-link-partnerships-active': isPartnershipVariableTrue }"><a href="partnership.html"
              class="header-link">{{ navbarLinkPartnerships }}</a>
          </div>
        </div>
        <x-button3 button="Log in" />
        <x-button22 button="Get started" :enrollment-link="enrollmentLink" />
      </div>
      <div class="frame-10-header-nav">
        <a :href="homeLink" class="logo-link">
          <img class="logo-header-nav" :src="logo" alt="Logo" :class="{ 'mobile-logo': mobileView }" />
        </a>
        <div :class="{ 'mobile-hide': mobileMenuHide }">
          <img class="menu-header" :class="{ 'mobile-logo': mobileView }" src="~@/assets/images/header/menu.svg"
            alt="mobile-nav" @click="showMobileDropdown('mobile')">
          <div v-if="mobileView" class="dropdown-menu-mobile">
            <div class="mobile-navigation-header">
              <div class="mobile-nav-close-button">
                <img class="window-close" src="~@/assets/images/header/window-close.svg" alt="window-close"
                  @click="showMobileDropdown('mobile')">
              </div>
            </div>

            <div class="dropdown-menu-item-mobile" @click="showMobileDropdown('mobile-product')">Products</div>
            <div class="dropdown-menu-item-mobile" @click="showMobileDropdown('mobile-resource')">Resources</div>
            <div class="dropdown-menu-item-mobile" @click="showMobileDropdown('mobile-company')">Company</div>
            <a :href="partnershipLink" class="link">
              <div class="dropdown-menu-item-mobile">Partnerships</div>
            </a>

            <div class="mobile-button-container">
              <x-button3 button="Log in" />
              <x-button22 button="Get started" :enrollment-link="enrollmentLink" />
            </div>

          </div>
        </div>

        <div v-if="mobileProductDropdown" class="dropdown-menu-mobile">
          <div class="mobile-navigation-header">
            <div class="mobile-nav-back-button">
              <img class="arrow-left" src="~@/assets/images/header/arrow-left.svg" alt="arrow-left"
                @click="goBackToMenu('mobile-product')">
            </div>
            <div class="mobile-nav-close-button">
              <img class="window-close" src="~@/assets/images/header/window-close.svg" alt="window-close"
                @click="goBackToMenu('mobile')">
            </div>
          </div>

          <a :href="steadypaceLink" class="link">
            <div class="dropdown-menu-item-mobile">SteadyPace</div>
          </a>
          <a :href="fastbreakLink" class="link">
            <div class="dropdown-menu-item-mobile">FastBreak</div>
          </a>
          <a :href="parityflexLink" class="link">
            <div class="dropdown-menu-item-mobile">ParityFlex</div>
          </a>
          <a :href="oneupLink" class="link">
            <div class="dropdown-menu-item-mobile">OneUp &dash; Coming soon</div>
          </a>
          <a :href="protectLink" class="link">
            <div class="dropdown-menu-item-mobile">Gainbridge Protect</div>
          </a>
          <div class="mobile-button-container">
            <x-button3 button="Log in" />
            <x-button22 button="Get started" :enrollment-link="enrollmentLink" />
          </div>
        </div>

        <div v-if="mobileResourceDropdown" class="dropdown-menu-mobile">
          <div class="mobile-navigation-header">
            <div class="mobile-nav-back-button">
              <img class="arrow-left" src="~@/assets/images/header/arrow-left.svg" alt="arrow-left"
                @click="goBackToMenu('mobile-resource')">
            </div>
            <div class="mobile-nav-close-button">
              <img class="window-close" src="~@/assets/images/header/window-close.svg" alt="window-close"
                @click="goBackToMenu('mobile')">
            </div>
          </div>
          <a :href="articlesLink" class="link">

            <div class="dropdown-menu-item-mobile">Articles</div>
          </a>
          <a :href="faqLink" class="link">
            <div class="dropdown-menu-item-mobile">FAQs</div>
          </a>
          <div class="mobile-button-container">
            <x-button3 button="Log in" />
            <x-button22 button="Get started" :enrollment-link="enrollmentLink" />
          </div>
        </div>

        <div v-if="mobileCompanyDropdown" class="dropdown-menu-mobile">

          <div class="mobile-navigation-header">
            <div class="mobile-nav-back-button">
              <img class="arrow-left" src="~@/assets/images/header/arrow-left.svg" alt="arrow-left"
                @click="goBackToMenu('mobile-company')">
            </div>
            <div class="mobile-nav-close-button">
              <img class="window-close" src="~@/assets/images/header/window-close.svg" alt="window-close"
                @click="showMobileDropdown('mobile')">
            </div>


          </div>
          <a :href="aboutusLink" class="link">
            <div class="dropdown-menu-item-mobile">About Us</div>
          </a>
          <a :href="careersLink" class="link">
            <div class="dropdown-menu-item-mobile">Careers</div>
          </a>
          <a :href="contactusLink" class="link">
            <div class="dropdown-menu-item-mobile">Contact</div>
          </a>

          <div class="gray-border-top-line-header"></div>

          <a :href="b2bLink" class="link" target="_blank">
            <div class="dropdown-menu-item-mobile">Enterprise
              <img :src="require('@/assets/images/header/new-tab.png')" alt="Open in new tab"
                class="new_tab_icon_mobile" />
            </div>
          </a>
          <div class="mobile-button-container">
            <x-button3 button="Log in" />
            <x-button22 button="Get started" :enrollment-link="enrollmentLink" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XButton22 from "@/components/header/XButton22.vue";
import XButton22Mobile from "@/components/header/XButton22Mobile.vue";
import XButton3 from "@/components/header/XButton3.vue";
import XButton3Mobile from "@/components/header/XButton3Mobile.vue";
import { getEnrollmentUrl } from "../utils";

export default {
  mounted() {
    // add a global click event listener to the document object
    document.addEventListener("click", this.handleGlobalClick);
    this.getActiveHeader(this.headerSection);
  },
  beforeUnmount() {
    // remove the global click event listener when the component is unmounted
    document.removeEventListener("click", this.handleGlobalClick);
  },

  data() {
    return {
      homeLink: "/home.html",
      steadypaceLink: "/steadypace.html",
      fastbreakLink: "/fastbreak.html",
      parityflexLink: "/parityflex.html",
      parityseriesLink: "/parityseries.html",
      oneupLink: "/oneup.html",
      protectLink: "/protect.html",
      faqLink: "/faq.html",
      articlesLink: "/articles.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      b2bLink: "https://www.enterprise.gainbridge.io",
      enrollmentLink: getEnrollmentUrl(),

      showProductsDropdown: false,
      showResourcesDropdown: false,
      showCompanyDropdown: false,
      showPartnershipsDropdown: false,

      isProductsVariableTrue: false,
      isProductOneUpVariableTrue: false,
      isProductSteadyPaceVariableTrue: false,
      isProductFastBreakVariableTrue: false,
      isProductComingSoonTrue: false,
      isResourceVariableTrue: false,
      isCompanyVariableTrue: false,
      isPartnershipVariableTrue: false,

      mobileMenuHide: false,
      mobileView: false,
      mobileProductDropdown: false,
      mobileResourceDropdown: false,
      mobileCompanyDropdown: false,
      emptyActive: false
      // other data properties
    }
  },
  methods: {
    getActiveHeader(header) {
      if (header === 'oneup') {
        this.isProductOneUpVariableTrue = true;
      }
      if (header === 'steadypace') {
        this.isProductSteadyPaceVariableTrue = true;
      }
      if (header === 'fastbreak') {
        this.isProductFastBreakVariableTrue = true;
      }
      if (header === 'comingsoon') {
        this.isProductComingSoonTrue = true;
      }
      if (header === 'products') {
        this.isProductsVariableTrue = true;
      }
      if (header === 'resources') {
        this.isResourceVariableTrue = true;
      }
      if (header === 'company') {
        this.isCompanyVariableTrue = true;
      }
      if (header === 'partnerships') {
        this.isPartnershipVariableTrue = true;
      }
      if (header === 'empty') {
        this.emptyActive = true;
      }
    },
    handleGlobalClick(event) {
      const navigation = this.$refs.navigation;
      if (this.showProductsDropdown === true && !navigation.contains(event.target)) {
        this.showProductsDropdown = !this.showProductsDropdown
      }
      if (this.showResourcesDropdown === true && !navigation.contains(event.target)) {
        this.showResourcesDropdown = !this.showResourcesDropdown
      }
      if (this.showCompanyDropdown === true && !navigation.contains(event.target)) {
        this.showCompanyDropdown = !this.showCompanyDropdown
      }
      if (this.showPartnershipsDropdown === true && !navigation.contains(event.target)) {
        this.showPartnershipsDropdown = !this.showPartnershipsDropdown
      }
      //
      // if (this.mobileView === true && !navigation.contains(event.target)) {
      //   this.mobileView = !this.mobileView
      // }
      if (this.mobileMenuHide === true && !navigation.contains(event.target)) {
        this.mobileMenuHide = !this.mobileMenuHide
        this.mobileView = false
      }
      if (this.mobileProductDropdown === true && !navigation.contains(event.target)) {
        this.mobileProductDropdown = !this.mobileProductDropdown
        this.mobileView = false
      }
      if (this.mobileResourceDropdown === true && !navigation.contains(event.target)) {
        this.mobileResourceDropdown = !this.mobileResourceDropdown
        this.mobileView = false
      }
      if (this.mobileCompanyDropdown === true && !navigation.contains(event.target)) {
        this.mobileCompanyDropdown = !this.mobileCompanyDropdown
        this.mobileView = false
      }
    },
    showDropdown(dropdown) {
      if (dropdown === 'products') {
        this.showProductsDropdown = true
      } else if (dropdown === 'resources') {
        this.showResourcesDropdown = true
      } else if (dropdown === 'company') {
        this.showCompanyDropdown = true
      } else if (dropdown === 'partnerships') {
        this.showPartnershipsDropdown = true
      }
      // other dropdown methods
    },
    hideDropdown(dropdown) {
      if (dropdown === 'products') {
        this.showProductsDropdown = false
      } else if (dropdown === 'resources') {
        this.showResourcesDropdown = false
      } else if (dropdown === 'company') {
        this.showCompanyDropdown = false
      } else if (dropdown === 'partnerships') {
        this.showPartnershipsDropdown = false
      }
      // other dropdown methods
    },
    showMobileDropdown(dropdown) {
      if (dropdown === 'mobile') {
        this.mobileView = !this.mobileView;
      } else if (dropdown === 'mobile-product') {
        this.mobileProductDropdown = !this.mobileProductDropdown;
        this.mobileMenuHide = true;
      } else if (dropdown === 'mobile-resource') {
        this.mobileResourceDropdown = !this.mobileResourceDropdown;
        this.mobileMenuHide = true;
      } else if (dropdown === 'mobile-company') {
        this.mobileCompanyDropdown = !this.mobileCompanyDropdown;
        this.mobileMenuHide = true;
      }
    },
    goBackToMenu(dropdown) {
      if (dropdown === 'mobile') {
        this.mobileView = !this.mobileView;
        this.mobileProductDropdown = false;
        this.mobileResourceDropdown = false;
        this.mobileCompanyDropdown = false;
      } else if (dropdown === 'mobile-product') {
        this.mobileProductDropdown = !this.mobileProductDropdown;
        this.mobileMenuHide = false;
      } else if (dropdown === 'mobile-resource') {
        this.mobileResourceDropdown = !this.mobileResourceDropdown;
        this.mobileMenuHide = false;
      } else if (dropdown === 'mobile-company') {
        this.mobileCompanyDropdown = !this.mobileCompanyDropdown;
        this.mobileMenuHide = false;
      }
    }
  },
  name: "Navigation2",
  components: {
    XButton22Mobile,
    XButton3Mobile,
    XButton3,
    XButton22,
  },
  props: [
    "logo",
    "navbarLinkProducts",
    "navbarLinkResources",
    "navbarLinkCompany",
    "navbarLinkPartnerships",
    "buttonProps",
    "button2Props",
    "headerSection",
  ]
};
</script>

<style>
.new-tab-symbol::after {
  content: "\2197";
  margin-left: 5px;
}

.navigation-1-header-nav {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  height: 80px;
  left: 0;
  justify-content: center;
  align-items: center;
  /*position: absolute;*/
  top: 0;
  /*width: 1440px;*/
}

.header-constrain {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
}

.frame-9-header-nav {
  align-items: flex-start;
  display: flex;
  flex: 1;
  padding: 19px 60px;
  position: relative;
  /*width: 1440px;*/
}

.logo-header-nav {
  /*align-self: center;*/
  height: 19px;
  margin-bottom: 7px;
  width: 156px;
}

.navbar {
  /*align-items: flex-start;*/
  /*align-self: center;*/
  /*display: flex;*/
  gap: 5px;
  justify-content: center !important;
  margin-left: 10%;
  margin-top: 5px;
  position: relative;
  width: 100%;
  flex-direction: row;
}

.navbar-link-header-nav {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0px;
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
  margin-right: 60px;

  /*width: 100%;*/
}

.navbar-link-header-nav-end {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 0px;
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
}

.dropdown-menu {
  display: block !important;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1;
  width: fit-content !important;
  text-align: left;
  padding: 0px !important;
  overflow: hidden;
  border-radius: 3px !important;
  border: none !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-menu-item {
  text-align: start;
  display: block;
  width: 100%;
  color: var(--black);
  cursor: pointer;
  margin: 20px;
}

.dropdown-menu-item:hover {
  color: var(--black-3);
}

.new_tab_icon {
  cursor: pointer;
  padding-bottom: 4px;
  margin-left: 5px;
}

.dropdown-menu-item-mobile {
  font-size: 32px;
  font-weight: 700;
  font-family: aktiv-grotesk, sans-serif;
  line-height: 41px;
  display: block;
  padding: 17px;

  color: var(--black);
  background-color: #FFFFFF;
  cursor: pointer;
}

.dropdown-menu-item-mobile:hover {
  background-color: #FFFFFF;
  color: var(--black-3);
}

.new_tab_icon_mobile {
  cursor: pointer;
  padding-bottom: 4px;
  margin-left: 5px;
  height: 24px;
  width: 20px;
}

.link {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}

.header-link {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}

.navbar-link-header-nav-end>a:hover {
  color: var(--black);
}

header-link:hover {
  color: var(--black);
  cursor: pointer;
  text-decoration: none;
}

.logo-link {
  align-self: center;
  cursor: pointer;
}

.nav-bar-link-products-active {
  padding-bottom: 8px;
  border-bottom: 2px solid #EFC21E;
}


.nav-bar-link-products-oneup-active {
  /*top: 1px;*/
  padding-bottom: 8px;
  border-bottom: 2px solid #00BD7B;
}

.nav-bar-link-products-coming-soon-active {
  /*top: 1px;*/
  padding-bottom: 8px;
  border-bottom: 2px solid #EFC21E;
}

.nav-bar-link-products-steadypace-active {
  /*top: 1px;*/
  padding-bottom: 8px;
  border-bottom: 2px solid #0F68F1;
}

.nav-bar-link-products-fastbreak-active {
  padding-bottom: 8px;
  border-bottom: 2px solid #0F68F1;
}

.nav-bar-link-resources-active {
  padding-bottom: 8px;
  border-bottom: 2px solid #EFC21E;
}

.nav-bar-link-company-active {
  padding-bottom: 8px;
  border-bottom: 2px solid #EFC21E;
}

.nav-bar-link-partnerships-active {
  padding-bottom: 8px;
  border-bottom: 2px solid #EFC21E;
}

.frame-10-header-nav {
  display: none;
  z-index: 2;
}

.menu-header {
  width: 32px;
  height: 32px;
  align-self: center;
  margin-bottom: 1px;
  margin-top: -6px;
  position: absolute;
  right: 0;
  margin-right: 5%;
  /*float: right;*/
  /*align-items: flex-end;*/
}

.mobile-logo {
  display: none;
}

.mobile-hide {
  display: none;
}


.arrow-left {
  height: 32px;
  width: 32px;
  margin-bottom: 20px;
  cursor: pointer;
}

.window-close {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.dropdown-menu-mobile {
  position: fixed;
  width: 100%;
  top: 0;
  display: block;
  background-color: #FFFFFF;
  left: 0;
}

.mobile-navigation-header {
  width: 100%;
  height: 70px;
  position: relative;
}

.mobile-button-container {
  width: 100%;
  height: 70px;
  position: absolute;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
}

.mobile-nav-back-button {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 17px;
}

.mobile-nav-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
}


@media (max-width: 1199.98px) {
  .navbar {
    margin-left: 2%;
  }

  .frame-9-header-nav {
    padding: 19px 10px;
  }
}


@media (max-width: 991.98px) {
  .navbar {
    margin-left: 2%;
  }

  .dropdown-menu {
    width: 100px;
  }
}


@media (max-width: 905px) {
  .frame-9-header-nav {
    display: none;
  }

  .frame-10-header-nav {
    align-items: flex-start;
    display: flex;
    flex: 1;
    padding: 19px 30px;
    position: relative;
  }
}

@media (max-width: 375px) {
  .frame-10-header-nav {
    padding: 20px 20px !important;
  }
}

.gray-border-top-line-header {
  border-top: 2px solid #E6E6E6;
  margin: 0px 18px;
}
</style>
