<template>
  <div class="w-full max-w-[874px]">
    <div class="flex flex-col gap-[16px] ">
      <h2 class="text-headline-6 text-black-l1">{{ title }}</h2>
      <div class="flex flex-col gap-[24px]">
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "H2Container",
  props: ["title"],
};
</script>