<template>
  <div class="footer-group-navigation">

    <div class="footer-nav-end">
      <footer3 />
    </div>
  </div>
</template>

<script>
import Footer3 from "./Footer3.vue";
export default {
  name: "Footer2",
  components: {
    Footer3,
  },
  props: ["footer3Props"],
};
</script>

<style>
.footer-nav-end {
  left: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 1440px;
}

.footer-group-navigation {
  position: absolute;
  width: 100%;
  left: 0;
  justify-content: center;
  background-color: #f8f9f9;
  border: 1px solid;
  display: flex;
  border-color: var(--mercury);
}
</style>
