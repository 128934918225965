<template>
  <div class="product-content-text2">
    <h2 class="col-lg-12 x-your2 aktivgrotesk-bold-black-32px" v-html="Title"></h2>
    <p class="col-lg-12 you-want-flexibility2" v-html="Content">
    </p>

  </div>
</template>

<script>
export default {
  name: "ContentText2",
  props: ["Title", "Content"],
};
</script>

<style>
.product-content-text2 {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  /*left: 170px;*/
  min-height: 202px;
  position: relative;
  order: 3;
  /*top: 1368px;*/
  /*width: 542px;*/
}



.x-you2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 41px;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-style: normal;
  font-weight:700;
  /*width: 538px;*/
}

.you-want-flexibility2 {
  letter-spacing: 0;
  line-height: 24px;
  /*width: 538px;*/
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px!important;
}

.span-3 {
  line-height: 24px;
}

.span1-1 {
  line-height: 27.2px;
}

.group-7552.group-7552-1 {
  /*left: 172px;*/
  min-height: 157px;
  /*top: 2973px;*/
}

.group-7552.group-7552-1 .you-want-flexibility {
  min-height: 101px;
}


@media (max-width: 992px) {
  .product-content-text2 {
    order:3;
    margin-top: 40px !important;
  }

  .you-want-flexibility2{
    font-size: 16px;
  }
}
</style>
