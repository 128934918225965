<template>
    <div class="w-full h-full max-w-[538px] flex flex-col gap-[15px]">
        <h2 class="text-headline-6 text-black-l1 mb-0">
            {{ title }}
        </h2>
        <p class="text-body-small text-black-l1 mb-0">
            <slot />
        </p>
    </div>
</template>

<script>
export default {
    name: "TextSection",
    props: ["title"]
};
</script>
