<template>
  <div class="product-content-text">
    <h2 class="col-lg-12 x-your aktivgrotesk-bold-black-32px" v-html="Title"></h2>
    <p class="col-lg-12 you-want-flexibility-part-16" v-html="Content">
    </p>
    <p class="name-quote-partnerships-quote" v-html="nameContent"></p>


  </div>
</template>

<script>
export default {
  name: "ContentTextQuote",
  props: ["Title", "Content", "nameContent"],
};
</script>

<style>
.product-content-text {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 7px;
  /*left: 170px;*/
  min-height: fit-content;
  position: relative;
  order: 1;
  /*top: 1368px;*/
  /*width: 542px;*/
}

.x-your {
  letter-spacing: 0;
  line-height: normal;
  min-height: 41px;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  /*width: 538px;*/
}

.you-want-flexibility-part-16 {
  margin-bottom: 0px !important;
  letter-spacing: 0;
  line-height: 24px;
  /*width: 538px;*/
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.span-3 {
  line-height: 24px;
}

.span1-1 {
  line-height: 27.2px;
}

.group-7552.group-7552-1 {
  /*left: 172px;*/
  min-height: 157px;
  /*top: 2973px;*/
}

.group-7552.group-7552-1 .you-want-flexibility {
  min-height: 101px;
}


@media (max-width: 992px) {
  .product-content-text {
    order: 3;
    margin-top: 24px !important;
  }

  .you-want-flexibility-part-16 {
    font-size: 16px;
  }
}

.name-quote-partnerships-quote {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 27.2px;
  position: relative;
  margin-top: 11px;
  /*white-space: nowrap;*/
  /*width: 538px;*/
}
</style>
