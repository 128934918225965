<template>
    <div
        class="rounded-2xl w-full bg-secondary-yellow-l5 flex flex-col md:flex-row p-12 gap-12 justify-between items-start md:items-center max-w-[1096px]">
        <div class="flex flex-col gap-2">
            <h2 class="text-headline-8 text-black-l1 leading-7">Gainbridge gives you a better way to grow your money
            </h2>
            <p class="text-subhead-x-small text-black-l1 max-w-[750px]">We let you buy annuities direct, getting rid of
                the
                commissions or fees a broker would take -- putting the power (and higher returns) back in your hands.
            </p>
        </div>

        <a href="http://www.gainbridge.io" class="w-[177px] shrink-0">
            <Button label="Explore our products" />
        </a>
    </div>
</template>

<script>
import { Button } from "@gainbridge-platform/frontend-component-library/dist/components/buttons/Button";

export default {
    name: "Banner",
    components: {
        Button
    },
    props: ["header", "subheader", "onClick"],
};
</script>