<template>
  <div class="floating-module" :style="{ 'background': backgroundColor }">
    <div class="want-to-take-control">{{ wantToTakeControl }}</div>
    <BarButton :buttonColor="barButtonColor" :href-link="hrefLink" :button-text="buttonText"/>
  </div>
</template>

<script>
import BarButton from "@/components/BarButton.vue";
export default {
  name: "FloatingModule",
  components: {
    BarButton,
  },
  props: ["backgroundColor", "wantToTakeControl", "barButtonColor", "hrefLink", "buttonText"],
};
</script>

<style>
.floating-module {
  align-items: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 31px;
  min-height: 294px;
  padding: 89px 0;
  position: relative;
  /*max-width: 300% !important;*/
  border-top-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
  margin-left: -5%;
  margin-right: -5%;
  margin-top: 150px;
}

@media (max-width: 905px) {
  .floating-module {
    align-items: center;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    gap: 31px;
    min-height: 294px;
    padding: 89px 0;
    position: relative;
  }
}

.want-to-take-control {
  color: var(--white);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-weight:700;
  letter-spacing: 0;
  line-height: normal;
  min-height: 41px;
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;

}
</style>
