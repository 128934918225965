<template>
  <div id="how-it-works-container"
    class="flex flex-col items-center w-full how-it-works-container bg-action-blue-l4 md:bg-white">
    <div id="how-it-works" class="
        how-it-works
        flex
        flex-col
        bg-action-blue-l4
        text-white
        py-[100px]
        items-center
        justify-center
        gap-[60px]
      ">
      <h4 class="text-headline-6">How it works</h4>
      <div id="how-it-works--steps-container"
        class=" items-center md:items-start justify-center flex flex-col md:flex-row gap-[75px] w-[1096px]"
        :style="`max-width: ${howItWorksStepsContainerStartingWidth}`">
        <HowItWorksStep :stepNumber="1" title="Apply in minutes"
          description="Open an account in under 10 minutes with an easy, online application." />
        <HowItWorksStep :stepNumber="2" title="Fund your annuity" :description="step2Copy" />
        <HowItWorksStep :stepNumber="3" title="Watch your money grow"
          description="To manage your account and see your growth, simply log into your Gainbridge® dashboard for real time updates." />

      </div>
      <a :class="buttonClasses" :href="buttonLink">Get started</a>
    </div>
  </div>
</template>

<script>
import { getButtonStyles } from "@gainbridge-platform/frontend-component-library/dist/components/buttons/Button";
import HowItWorksStep from './HowItWorksStep.vue';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let timeline;

export default {
  components: {
    HowItWorksStep
  },
  props: ['buttonLink', 'step2Copy'],
  data() {
    return {
      scrollEffectIsActive: false,
      howItWorksStepsContainerStartingWidth: "100%",
      buttonClasses: getButtonStyles({ type: 'secondary-white', className: 'no-underline w-fit' })
    }
  },
  created() {
    window.addEventListener("resize", this.onResizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResizeHandler);
  },
  methods: {
    onResizeHandler(e) {
      if (window.matchMedia("(max-width: 767px)").matches) {
        timeline.scrollTrigger.disable()
        document.getElementById("how-it-works").setAttribute('style', '')
      } else {
        timeline.scrollTrigger.enable()
      }
    }
  },
  mounted() {
    /**
     * the stretch/zoom animation doesn't happen on mobile, so we won't even
     * load this on screens that are under 767px. we have to do 767 (instead
     * of 768, which we're using in the css) in the if statement because
     * there's match or not match; no <= or >=.
     */
    this.$nextTick(() => {
      this.howItWorksStepsContainerStartingWidth = `${document.getElementById("how-it-works--steps-container").offsetWidth}px`;

      timeline = gsap.timeline({
        scrollTrigger: {
          trigger: "#how-it-works",
          // when the top of the trigger is 100px below the top of the viewport
          start: "top top+=400",
          end: "+=325", // end after scrolling 500px
          scrub: 1,
        },
      });

      timeline
        // how it works zoom
        .to("#how-it-works", {
          // styles to animate. animates from original values to these.
          width: "100%",
          height: "600px", // starts at a fit-content-calculated 464px
          borderRadius: "0"
        })
        // stretch the height of the background as we scroll so it doesn't
        // overlap the following section
        .to("#how-it-works-container",
          {
            // height: "1144px" // starts at a fit-content-calculated 644px
          },
          "<" // starts at the same time as the previous animation
        );

      if (window.matchMedia("(max-width: 767px)").matches) {
        timeline.scrollTrigger.disable()
      }
    });


  },
}
</script>

<style>
.how-it-works {
  width: 100%;
  max-height: none !important;
  padding: 0 16px;
}

@media (min-width: 768px) {
  .how-it-works {
    width: 80%;
    padding: 0 70px;
    border-radius: 40px;
  }
}

#how-it-works {
  max-width: none !important;
}
</style>