
<template>
  <div id="mylo"></div>
</template>

<script>
//====================================================================
// Documentation: https://api.choosemylo.com/#section/Implementation
//
// product: 'Auto' | 'Commercial' | 'Home' | 'SGB'
// Required environment variables:
//   ENVIRONMENT
//   MYLO_CAMPAIGN_ID
//====================================================================
export default {
  name: 'MyloEmbed',
  props: [
    "campaignId",
    "product",
    "scriptLink"
  ],
  mounted() {
    this.addScript();
  },
  methods: {
    addScript() {
      if (!this.campaignId) {
        throw new Error("Configuration error: missing campaign ID");
      }

      let myloScript = document.createElement("script");
      myloScript.setAttribute("src", this.scriptLink);
      myloScript.onload = () => {
        this.render();
      };
      document.head.appendChild(myloScript);
    },
    createCustomerId() {
      return `${new Date().getTime()}`;
    },
    render() {
      try {
        const options = {
          tracking: {
            campaignid: this.campaignId,
            xcid: this.createCustomerId(),
          },
        };

        new window.mylo.embeds.buyflow[this.product](
          document.getElementById("mylo"),
          options,
        );
      } catch (e) {
        console.error("Embed failed!");
        console.error(e);
      }
    },
  },
  components: {},
}
</script>

<style scoped>
#mylo {
  margin-top: 80px;
}
</style>